import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import styles from "./FileArea.module.scss";
import StyledCheckbox from "../../components/shared/StyledCheckbox/StyledCheckbox";
import BtButton from "../bt-button/bt-button";
import { formatDateTime, formatDistanceToNow, humanizeBytes } from "../../util";
import DownloadButton from "../../components/shared/DownloadButton";
import { ReactComponent as FileIconBackground } from '../../assets/svg/file-icon-background-darker.svg';
import { ReactComponent as ArrowIcon } from '../../assets/svg/arrow_stroke_down.svg';
import { ReactComponent as SuccessIcon } from '../../assets/svg/successIcon.svg';
import { ReactComponent as FolderIcon } from '../../assets/svg/folderIcon.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/svg/upArrowIcon.svg';
import { ReactComponent as NavigationArrowIcon } from '../../assets/svg/navigationArrowIcon.svg';
import { ReactComponent as UploadIcon } from '../../assets/svg/uploadIcon.svg';
import { ReactComponent as CreateFolder } from '../../assets/svg/createFolderIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/svg/downloadIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/deleteIcon.svg';
import { CustomSort } from "../../components/shared/CustomSort";
import { Sort } from "../../services/service-supplier-monitoring";
import serviceFiles, { FileNode, FileOrFolder } from "../../services/service-files";
import Spinner from "../../components/shared/Spinner/Spinner";
import { ErrorMessageIcon } from "../../components/shared/ErrorMessageIcon/ErrorMessageIcon";
import appState from "../../state/AppStateContainer";
import { AxiosProgressEvent } from "axios";
import axios from "axios";
import fileDownload from "js-file-download";
import _ from "lodash";
import Modal from "../../components/shared/Modal/Modal";
import Field from "../../components/shared/Field/Field";
import { TinyRippler } from "../../components/shared/TinyRippler/TinyRippler";
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import { NotificationManager } from 'react-notifications';
import * as Sentry from "@sentry/react";

const genericCompare = (a: string | number | Date, b: string | number | Date, order: 'asc' | 'desc') => {
  if (typeof a === 'string' && typeof b === 'string') {
    return order === 'desc' ? b.toLowerCase().localeCompare(a.toLowerCase()) : a.toLowerCase().localeCompare(b.toLowerCase());
  }
  if (a < b) {
    return order === 'asc' ? 1 : -1;
  } else if (a > b) {
    return order === 'asc' ? -1 : 1
  } else {
    return 0;
  }
}

const FileIcon = ({ fileName, className }: { fileName: string, className?: string }) => {
  const [icon, setIcon] = useState('');

  useEffect(() => {
    const importAll = (source: { keys: () => string[] } & ((filename: string) => string)) => {
      const images: { [filename: string]: string } = {};
      source.keys().forEach(item => { images[item.replace('./', '')] = source(item); });
      return images
    }

    const getFileExtension = (fileName: string) => {
      return fileName?.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    }

    const checkImage = (src: string, bad: () => void) => {
      const img = new Image();
      img.onerror = bad;
      img.src = src;
    }

    const images = importAll(require.context('../../assets/file-icons/', false, /\.(png|jpe?g|svg)$/));
    const tempFileIcon = images[`${getFileExtension(fileName.toLowerCase())}.svg`];
    const tempIcon = tempFileIcon ? tempFileIcon : images['blank.svg'];
    setIcon(tempIcon);
    checkImage(tempIcon, () => {
      setIcon(images['blank.svg']);
    });
  }, [])

  return (
    <div className={`${styles.fileIconContainer} ${className}`}>
      <FileIconBackground />
      <img src={icon} />
    </div>
  )
}

type FileRowProps = {
  root: string,
  path: string,
  file: ManagedFile,
  highlightedFiles: string[],
  setHighlightedFiles: React.Dispatch<React.SetStateAction<string[]>>,
  selectedFiles: string[],
  setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>,
  disabled?: boolean,
  setPath: (getNewPath: (path: string) => string) => void,
}

type Upload = {
  uploadId: string,
  name: string,
  size: number,
  progress: number,
  errorMessage?: string,
};

const FileRow: FC<FileRowProps> = ({ root, path, file, highlightedFiles, setHighlightedFiles, selectedFiles, setSelectedFiles, disabled, setPath, }) => {
  const createdAt = new Date(file.createdAt);
  const disabledRow = disabled || file.uploadId || file.errorMessage;
  const orgSlug = appState.getCurrentOrgSlug();
  return (
    <tr
      className={`
        ${highlightedFiles.includes(file._id) ? styles.highlighted : ''}
        ${selectedFiles.includes(file._id) ? styles.selected : ''}
        ${disabledRow ? styles.disabledRow : ''}
      `}
      onClick={() => {
        setHighlightedFiles([file._id]);
        if (disabledRow) return;
        setSelectedFiles([file._id]);
      }}
    >
      <td className={styles.fileNameColumn}>
        <DownloadButton
          url={`/api/organizations/${orgSlug}${root}${path}/${file.name}`}
          fileName={file.name}
          render={({onClick, progress, downloading}) => {
            let status;
            if (file.errorMessage) {
              status = <ErrorMessageIcon errorMessage={file.errorMessage} style={{ width: '19px', }} />
            } else if (file.doneAt) {
              status = <SuccessIcon />
            } else if (downloading || (file.progress && file.progress < 1)) {
              status = <Spinner isHidden={!downloading && !file.progress} size='xxs' progress={file.progress ?? progress} style={{ marginRight: '1px' }} />
            }
            return (
              <div className={styles.file}>
                <div>
                  <StyledCheckbox
                    color="var(--light-blue-600)"
                    checked={selectedFiles.includes(file._id)}
                    onChange={() => {
                      if (disabledRow) return;
                      setSelectedFiles(prevState => {
                        if (prevState.includes(file._id)) {
                          setHighlightedFiles([]);
                          return prevState.filter(f => f !== file._id);
                        } else {
                          setHighlightedFiles([file._id]);
                          return [...prevState, file._id];
                        }
                      });
                    }}
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    style={{ opacity: selectedFiles.length > 0 ? 1 : 0, visibility: disabledRow ? 'hidden' : 'visible', pointerEvents: disabledRow ? 'none' : 'auto' }}
                    className={styles.checkbox}
                  />

                  {file.type === 'FOLDER' ? (
                    <FolderIcon style={{ marginLeft: '2px', marginRight: '8px' }} />
                  ) : (
                    <FileIcon className={styles.fileIcon} fileName={file.name} />
                  )}
                  <span
                    onClick={(e) => {
                      if (onClick && !disabledRow) {
                        if (file.type === 'FOLDER') {
                          setPath(oldPath => {
                            const newPath = `${oldPath}${oldPath === '/' ? file.name : `/${file.name}`}`;
                            return newPath;
                          });
                        } else {
                          onClick();
                        }

                        e.stopPropagation();
                      }
                    }}
                    className={styles.fileName}
                  >
                    {file.name}
                  </span>
                </div>
                <div>{status}</div>
              </div>
            )
          }}
        />
      </td>
      <td>{file.type == "FILE" && humanizeBytes(file.size)}</td>
      <td title={formatDateTime(createdAt)}>{formatDistanceToNow(createdAt)}</td>
      <td>{file.createdBy}</td>
    </tr>
  )
}

type CustomSortHeaderProps = {
  sort: Sort,
  setSort: React.Dispatch<React.SetStateAction<Sort>>,
  field: string,
}

const CustomSortHeader: FC<CustomSortHeaderProps> = ({ sort, setSort, field, children }) => {
  return (
    <CustomSort sort={sort} setSort={setSort} field={field} className={styles.tableHeader}>
      {children}
      <ArrowIcon className={sort.field === field && sort.order ? styles[sort.order]: ''} width={10} />
    </CustomSort>
  )
}

type ManagedState = {
  errorMessage?: string,
  doneAt?: Date,
  progress?: number,
}

type ManagedFile = FileOrFolder  & ManagedState & {
  uploadId?: string,
}

type Props = {
  root: string,
  path: string,
  setPath: (getNewPath: (path: string) => string) => void,
}

let nextUploadId = 1;

const FileArea: FC<Props> = ({ root, path, setPath, }) => {
  const [files, setFiles] = useState<ManagedFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [highlightedFiles, setHighlightedFiles] = useState<string[]>([]);
  const [sort, setSort] = useState<Sort>({});
  const [uploads, setUploads] = useState<Map<string, Upload>>(new Map());  
  const [downloading, setDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [deletingFiles, setDeletingFiles] = useState(false);

  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const orgSlug = appState.getCurrentOrgSlug();

  useEffect(() => {
    (async () => {
      setLoadingFiles(true);
      const files = await serviceFiles.listFiles(root, path);
      setSelectedFiles([]);
      setHighlightedFiles([]);
      setSort({});
      setFiles(files.children);
      setLoadingFiles(false);
    })();

    const handleClick = (e: MouseEvent) => {
      const tbody = tbodyRef.current;

      if (!tbody?.contains(e.target as Node)) {
        setHighlightedFiles([]);
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [path]);

  const sortedFiles = useMemo(() => {
    const filesAndUploads = [...files, ...(Array.from(uploads.values()).map(u => ({
      id: u.uploadId,
      uploadId: u.uploadId,
      name: u.name,
      size: u.size,
      progress: u.progress,
      type: "FILE",
      parentId: "",  
      createdAt: new Date(),
      createdBy: appState.getUserName(),  
      errorMessage: u.errorMessage,
    })))] as ManagedFile[];
    return [...filesAndUploads].sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === "FOLDER" ? -1 : 1;
      }
      const field = sort.field ?? "name";
      const order = sort.order ?? "asc";
      return genericCompare(a[field as keyof FileOrFolder]!, b[field as keyof FileOrFolder]!, order);
    });
  }, [files, sort, uploads]);

  const removeFiles = useCallback(async (fileIds: string[]) => {
    setDeletingFiles(true);
    setSelectedFiles([]);
    await Promise.all(fileIds.map(async fileId => {
      const file = files.find(file => file._id === fileId);
      if (file) {
        await serviceFiles.deleteFileOrFolder(root, `${path}/${file.name}`);
      }
    }));
    setFiles(files.filter(file => !fileIds.includes(file._id)));
    setDeletingFiles(false);
  }, [root, path, files]);

  const downloadFiles = useCallback(async (filesIds: string[]) => {
    setDownloading(true);
    const filesToDownload: (FileNode & ManagedState)[] = filesIds.map(fileId => files.find(file => file._id === fileId)) as (FileNode & ManagedState)[];
    await Promise.all(filesToDownload.filter(file => file.type === 'FILE').map(async file => {
      if (file) {
        try {
          const response = await axios.get(`/api/organizations/${orgSlug}${root}${path}/${file.name}`, {
            onDownloadProgress: (e: AxiosProgressEvent) => {
              setFiles(prev => {
                const prevFile = prev.find(f => f._id === file._id);
                if (prevFile) {
                  prevFile.progress = e.loaded / (e?.total || 1);
                }
                return [...prev];
              });
            },
            responseType: 'blob',
          });
      
          fileDownload(
            response.data,
            file.name,
            file.mimeType,
          );
  
          setSelectedFiles(prevState => prevState.filter(f => f !== file._id));
        } catch (error) {
          const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
          setFiles(prev => {
            const prevFile = prev.find(f => f._id === file._id);
            if (prevFile) {
              prevFile.errorMessage = errorMsg;
            }

            return prev;
          })
        }
      }
    }));

    setDownloading(false);
  }, [files, root, path]);

  const uploadFiles = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const promises = [];
    for (let i = 0; i < event.target.files!.length; i++) {
      const uploadId = "$upload-id-" + (nextUploadId++);
      const file = event.target.files![i];
      const onUploadProgress = (progressEvent: AxiosProgressEvent) => {                
        setUploads(prev => {
          const map = new Map(prev);
          const upload = map.get(uploadId) ?? { uploadId, name: file.name, size: file.size, progress: 0 };
          return map.set(uploadId, { ...upload, progress: progressEvent.progress ?? 0 });
        });
      }
      setUploads(prev => {
        const map = new Map(prev);
        const existingUploadsWithError = Array.from(map.values()).filter(u => u.name == file.name && u.errorMessage);
        for (const u of existingUploadsWithError) {
          map.delete(u.uploadId);
        }
        const upload = { uploadId, name: file.name, size: file.size, progress: 0 };
        return map.set(uploadId, upload);
      });
      promises.push(serviceFiles.uploadFile(root, path, file, onUploadProgress).then((uploadedFile) => {
        setUploads(prev => {
          const map = new Map(prev);
          map.delete(uploadId);
          return map;
        });
        setFiles(prev => [...prev, { ...uploadedFile, doneAt: new Date() }]);
        setTimeout(() => {
          setFiles(prev => prev.map(f => {
            if (f._id === uploadedFile._id) {
              f.doneAt = undefined;
            }
            return f;
          }));
        }, 5000);
      }).catch((error) => {
        setUploads(prev => {
          const map = new Map(prev);
          const upload = map.get(uploadId) ?? { uploadId, name: file.name, size: file.size, progress: 0 };
          return map.set(uploadId, { ...upload, errorMessage: error.response?.data?.error ?? error.message });
        });
      }));
    }    
    event.target.value = "";
    await Promise.all(promises);
  }, [root, path]);

  const createFolder = useCallback(async (name: string) => {
    if (!name || name.trim() === '' || name.includes('/')) return;

    setCreatingFolder(true);
    try {
      const folder = await serviceFiles.createFolder(root, path, name);
      setFiles(prev => [...prev, { ...folder, createdAt: new Date(), createdBy: appState.getUserName(), }]);
      setShowModal(false);
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setCreatingFolder(false);
    }
  }, [root, path]);

  const popPath = useCallback(() => {
    setPath(prev => {
      let newPath = prev.split('/').slice(0, -1).join('/');
      if (newPath === '') {
        newPath = '/';
      }
      
      return newPath;
    })
  }, [path]);

  const isAdminUser = appState.isAdminRole();

  const isDownloadButtonDisabled = !!selectedFiles.find(fileId => files.find(f => f._id === fileId && f.type === 'FOLDER'));
  const splitPath = path.split('/');

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {selectedFiles.length === 0 ? (<>
          {isAdminUser && (
            <label style={{ letterSpacing: 'normal' }}>
              <BtButton color="white" size="xxs" disabled={downloading || deletingFiles} leftIcon={<UploadIcon />}>
                <input style={{ display: "none" }} type="file" multiple onChange={uploadFiles} disabled={downloading || deletingFiles} />
                Ladda upp filer
              </BtButton>
            </label>
          )}
          <BtButton color="white" size="xxs" leftIcon={<CreateFolder />} disabled={downloading || deletingFiles} onClick={() => {
            setFolderName('');
            setShowModal(true);
          }}>
            Skapa mapp
          </BtButton>
        </>) : (
          <>
            <TooltipContainer
              renderReferenceComponent={(className, ref) => 
                <div
                  ref={ref}
                  className={className}
                >
                  <BtButton
                    color="white"
                    size="xxs"
                    onClick={() => downloadFiles(selectedFiles)}
                    loaderShow={downloading}
                    disabled={isDownloadButtonDisabled}
                    leftIcon={<DownloadIcon />}
                  >
                    Ladda ner
                  </BtButton>
                </div>
              }
            >
              {isDownloadButtonDisabled && <span style={{ color: 'var(--gray-700)', fontSize: '12px' }}>Det går inte att ladda ner mappar.</span>}
            </TooltipContainer>
            {isAdminUser && !downloading && (
              <BtButton
                color="white"
                size="xxs"
                onClick={() => removeFiles(selectedFiles)}
                loaderShow={deletingFiles}
                leftIcon={<DeleteIcon />}
              >
                Ta bort {selectedFiles.length} fil{selectedFiles.length > 1 ? 'er' : ''}
              </BtButton>
            )}
          </>
        )}
      </div>
      <div className={`${styles.pathContainer} ${path === '/' ? styles.isRoot : ''}`}>
        <div onClick={() => !loadingFiles && popPath()}>
          <UpArrowIcon />
        </div>
        <div className={styles.path}>
          {path === '/' ? <span key={'static'}>Filer</span> : (
            splitPath.map((p, index) => {
              const pathKey = splitPath.slice(0, index + 1).join('/');
              return (
                <>
                  {index > 0 && <NavigationArrowIcon key={pathKey + 'arrow'} className={styles.arrow} />}
                  <span
                    key={pathKey}
                    onClick={() => {
                      if (splitPath.length - 1 !== index && !loadingFiles) {
                        setPath(() => pathKey);
                      }
                    }}
                  >
                    {p === '' ? 'Filer' : p}
                  </span>
                </>
              )
            })
          )}
          <TinyRippler show={loadingFiles || deletingFiles} />
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <CustomSortHeader
                sort={sort}
                setSort={setSort}
                field='name'
              >
                <StyledCheckbox
                  dash={selectedFiles.length > 0 ? selectedFiles.length !== files.length : false}
                  color="var(--light-blue-600)"
                  checked={selectedFiles.length > 0}
                  onChange={() => {
                    if (downloading) return;
                    setSelectedFiles((prevState) => prevState.length > 0 ? [] : files.filter(f => !f.uploadId).map(f => f._id));
                    setHighlightedFiles([]);
                  }}
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                  }}
                  style={{ visibility: downloading ? 'hidden' : 'visible' }}
                />
                Namn
              </CustomSortHeader>
            </th>
            <th>
              <CustomSortHeader
                sort={sort}
                setSort={setSort}
                field='size'
              >
                Storlek
              </CustomSortHeader>
            </th>
            <th>
              <CustomSortHeader
                sort={sort}
                setSort={setSort}
                field='createdAt'
              >
                Datum
              </CustomSortHeader>
            </th>
            <th>
              <CustomSortHeader
                sort={sort}
                setSort={setSort}
                field='createdBy'
              >
                Användare
              </CustomSortHeader>
            </th>
          </tr>
        </thead>
        <tbody ref={tbodyRef}>
          {sortedFiles.map(file => (
            <FileRow            
              key={file._id}
              root={root}
              path={path}
              file={file}
              highlightedFiles={highlightedFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setHighlightedFiles={setHighlightedFiles}
              disabled={downloading || loadingFiles || deletingFiles}
              setPath={setPath}
            />
          ))}
        </tbody>
      </table>
      <Modal
        title="Skapa mapp"
        show={showModal}
        setShow={setShowModal}
        buttonInfo={{
          label: 'Skapa',
          action: async () => {
            await createFolder(folderName);
          },
          loaderShow: creatingFolder,
          disabled: !folderName || folderName.trim() === '' || folderName.includes('/'),
        }}
      >
        <div>
          <Field
            label="Namn"
            value={folderName}
            onChange={setFolderName}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await createFolder(folderName);
              }
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default FileArea;