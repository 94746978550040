import axios, { AxiosProgressEvent } from "axios";

export type FolderNode = {
  type: "FOLDER",
  name: string,
  _id: string,
  parentId: string,  
  createdAt: Date,
  createdBy: string,
}

export type FileNode = {
  type: "FILE",
  name: string,
  _id: string,
  parentId: string,  
  createdAt: Date,
  createdBy: string,
  mimeType: string,
  size: number,
}

export type FileOrFolder = FileNode | FolderNode;

export type Result = {
  name: string,
  _id: string,
  children: FileOrFolder[],
}

class ServiceFiles {
    async uploadFile(root: string, path: string, file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) : Promise<FileNode> {
    const formData = new FormData();
    formData.set("file", file);
    const response = await axios.post(`/api${root}${path}`, formData, { onUploadProgress });
    return response.data;
  }
  async listFiles(root: string, path: string) : Promise<Result> {
    const response = await axios.get(`/api${root}${path}`);
    return response.data;
  }

  async createFolder(root: string, path: string, name: string) {
    const response = await axios.post(`/api${root}${path}`, { name });
    return response.data;
  }

  async deleteFileOrFolder(root: string, path: string) {
    const response = await axios.delete(`/api${root}${path}`);
    return response.data;
  }
}

export default new ServiceFiles();