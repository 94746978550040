import React from 'react';
import { Add } from '../../components/svgs';
import appState from '../../state/AppStateContainer';
import BtButton from '../bt-button/bt-button';
import { ServiceDownload } from '../../services/service-download';
import ExcelIcon from '../../assets/images/excel.png';
import Tabs from '../../components/shared/Tabs';
import NotificationCounter from '../../components/shared/NotificationCounter/NotificationCounter';
import { TabNames } from './supplier-database';

const TableTopActions = ({ header, onAddArea, onAddSupplier, isReadOnlyUser, selectedTab, onTabChange, numberOfRecommendedSuppliers, selectMode, inboxCount, onShowSupplierApplicationInfo, isFeaturedSupplier, selectedTabName }) => {
  const tabs = ['Leverantörsdatabas'];
  if (!selectMode) {
    tabs.push(<span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>Ansökningar <NotificationCounter style={{ backgroundColor: 'var(--gray-200)', color: 'var(--gray-800)' }} counter={inboxCount} /></span>);
    tabs.push(<span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>Nekade ansökningar</span>);

    if (isFeaturedSupplier) {
      tabs.push(<span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>Utvald leverantör</span>);
    }

    const domain = window.location.href.split('/')[2];
    if (appState.isSuperAdminOrg()) {
      tabs.push(<span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>Annonsansökningar</span>)
    }
  }
  if (selectMode && numberOfRecommendedSuppliers > 0) {
    tabs.push(<span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>Rekommenderade leverantörer <NotificationCounter style={{ backgroundColor: 'var(--gray-200)', color: 'var(--gray-800)' }} counter={numberOfRecommendedSuppliers} /></span>);
  }

  return (
    <div className="sp-top-actions">
      <div className="tab-container">
        <Tabs tabs={tabs} selected={selectedTab} onChange={onTabChange} style={{ marginBottom: '20px' }} />
        {!isReadOnlyUser && (
          <div className="sp-btn-group">
            {appState.isManagementRole() && selectedTabName !== TabNames.AD_APPLICATIONS && (
              <BtButton
                class="white"
                onClick={async () => {
                  await ServiceDownload.downloadSupplierExcelFile();
                }}
                leftIcon={
                  <img className="excel-icon--style" src={ExcelIcon} width="25px" height="25px" />
                }
                style={{ border: '1px solid var(--gray-300)' }}
              >
                Exportera leverantörer
              </BtButton>
            )}


            {onAddArea && selectedTabName !== TabNames.AD_APPLICATIONS && appState.isAdminRole() && (
              <BtButton class='white' onClick={onAddArea}>
                <Add fill="var(--orange-500)" />
                <span>Lägg till yrkeskategori</span>
              </BtButton>
            )}

            {onAddSupplier && (selectMode || selectedTab != 1) && selectedTabName !== TabNames.AD_APPLICATIONS && (
              <BtButton onClick={onAddSupplier}>
                <Add />
                <span>Lägg till leverantör</span>
              </BtButton>
            )}

            {onShowSupplierApplicationInfo && (!selectMode && selectedTab == 1) && (
              <BtButton class='white' onClick={onShowSupplierApplicationInfo}>Ansökningsformulär</BtButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableTopActions;
